.editableCell {
  margin: 4px 0;
  cursor: pointer;
  max-height: 80%;
  max-width: 100%;
  width: 100%;
  display: flex;
  padding: 6px;
  align-items: center;
}

.editableCell:hover {
  border-radius: 4px;
  padding: 6px;
  background-color: var(--neutralBlack4);
}

.editInput {
  width: 100% !important;
}

.editInput input {
  min-width: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
}

.amountDisplay {
  width: 100%;
  justify-content: end !important;
  text-align: right;
}

.inputRightAlign {
  text-align: right;
  direction: rtl;
  unicode-bidi: plaintext;
}
