.container {
  border: 1px solid var(--neutralBlack12);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  width: 105px;
}

.status {
  display: flex;
  flex: 1;
}

.service {
  width: 80px;
  display: flex;
  justify-content: end;
}

.progressLine {
  position: relative;
}
.progressLine:before {
  background: var(--neutralBlack12);
  bottom: 14px;
  content: "";
  left: 6.5px;
  position: absolute;
  width: 1px;
  top: 14px;
  z-index: 0;
}

.row {
  align-items: center;
  display: flex;
  gap: 8px;
  height: 40px;
  position: relative;
}

.icon {
  align-items: center;
  background: var(--neutralWhite100);
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  height: 14px;
  justify-content: center;
  width: 14px;
  z-index: 2;
}

.content {
  max-width: 480px;
}

.errorMessage {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.serviceIcon {
  height: 12px;
  width: 12px;
}
