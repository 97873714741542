.section {
  padding: 15px 15px 0px 15px;
}

.errorMessage {
  color: var(--danger400);
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 0px 15px 0px 0px;
}

.searchItem {
  height: 60px;
  border-radius: 8px;
}

.searchBar input {
  height: 36px;
  border-radius: 4px !important;
}

.commonAttributes {
  background: #e3ddff;
}
