.areaTitle {
  display: none;
  position: absolute;
  top: -151px;
  z-index: 2;
  left: 0;
  padding: 10px 30px;
  background-color: #fff;
}
.areaTitleWithClipPath {
  top: 0px;
}
.area {
  position: absolute;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23757575FF' stroke-width='10' stroke-dasharray='54%2c45' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e");
}

.areaWithoutBorder {
  background-image: none;
}

.area:hover {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='16' stroke-dasharray='54%2c45' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  background-color: (255, 255, 255, 0.07);
}

.area:hover .areaTitle {
  display: block;
}

.suggestedLocationArea {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(43 142 250)' stroke-width='16' stroke-dasharray='54%2c45' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e") !important;
}
