.orderUpdateModalContainer {
  width: 30vw;
  max-width: 30vw;
  padding: 15px;
  border-radius: 4px !important;
}

.orderUpdateModalMargin {
  margin: 2vh auto;
}
