.drawerSections {
  padding: 5px 20px 120px 5px;
  height: 100%;
  overflow: auto;
}
.btnRounded {
  border-radius: 50%;
  min-height: 28px;
  min-width: 28px;
}
.offerGroup a {
  font-size: 0.9rem;
  text-decoration: none;
  color: #333;
}
.offerGroup a:hover {
  color: #5ec9d5;
}
.incomingDate {
  display: inline-flex;
  align-items: center;
  color: #8f969f;
  font-size: 0.9rem;
}
.disabledLink {
  opacity: 0.7;
  pointer-events: auto !important;
  cursor: not-allowed;
}
.incomingDate i {
  display: inline-flex;
  border-radius: 4px;
  width: 4px;
  height: 4px;
  background: #8f969f;
}
.dateInput::-webkit-clear-button,
.timeInput::-webkit-clear-button {
  opacity: 0.0001;
}
.dateInput::-webkit-clear-button,
.timeInput::-webkit-clear-button {
  opacity: 0.0001;
}
.dateInput::-moz-clear {
  opacity: 0.0001;
}
.dateInput::-moz-clear,
.timeInput::-moz-clear {
  opacity: 0.0001;
}
.dateInput,
.timeInput {
  padding-right: 0;
  width: 90px;
  max-width: 90px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid transparent;
  margin: 0 -5px;
  padding: 0 0 0 5px;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: listbox;
}
.dateInput:hover,
.timeInput:hover {
  background: url(~assets/images/11.svg) no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: center;
}
.dateInput:focus,
.timeInput:focus {
  background: url(~assets/images/11.svg) no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: center;
  box-shadow: #765ee3 0px 0px 0px 1px;
  outline: none;
}
.inputShort {
  width: 80px;
  max-width: 80px;
  margin-right: 15px;
}
.lastElementBorder {
  box-shadow: 0px 0px 0px 7px white, 0px 0px 0px 8px #755fe2;
  border-radius: 1px;
  margin-top: 5px;
}
.productRow {
  position: relative;
  min-height: 52px;
}
.product {
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  /* max-width: 225px; */
}
.productName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  overflow: hidden;
}
.versionBox img {
  width: 18px;
  height: 18px;
  background: #eee;
}
.versionBox .label {
  display: inline-flex;
  width: fit-content;
  font-size: 0.75rem;
  height: 18px;
  line-height: 18px;
  padding: 0 3px;
  background: #eee;
  border: 0;
  white-space: nowrap;
}

.weight {
  font-size: 0.9rem;
  color: #808080;
  font-weight: 500;
}
.productRow .editIcon {
  display: inline-flex;
  visibility: hidden;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.messageIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #eee;
  margin: 9px 10px 9px 0;
  padding: 4px;
}
.messageIcon img {
  display: block;
  width: 100%;
}
.btnReady {
  background-color: #01c9d5;
  color: #fff;
  border-color: #01c9d5;
}
.btnReady img {
  filter: contrast(0%) brightness(200%);
}
.inputIcon {
  width: 18px;
  min-width: 18px;
  padding: 3px;
  margin-right: 10px;
  border-radius: 50%;
  background: #eee;
}
.inputIcon img {
  display: block;
  width: 100%;
}
.hasComment {
  color: #ff6e75;
  text-transform: lowercase;
}
.noLogin,
.noLink,
.noComment {
  color: #808080;
}
.noLink {
  font-size: 0.9rem;
}
.shortInput {
  width: 75px;
  max-width: 75px;
}
.noAttributes {
  color: #808080;
  font-size: 0.9rem;
}
.accountLabel {
  color: #fff;
}
.inputLabel {
  position: absolute;
  top: -5px;
  font-size: 12px;
  background: #fff;
  color: #808080;
  padding: 2px;
  display: inline-flex;
  z-index: 2;
}
.leftDaysInput {
  position: relative;
  z-index: 1;
}
.leftDaysLabel {
  font-size: 12px;
  background: #fff;
  color: #808080;
  position: absolute;
  top: -4px;
}
.leftDaysInputBox {
  width: 120px;
}
.reclamationSignature {
  color: #01c9d5;
}
.subtitle {
  font-size: 0.81rem;
}
.reclamationList {
  margin: 0;
  padding: 0;
  padding-left: 20px;
}
.spinner {
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100vh - 150px);
}
.subtitle {
  width: 100%;
  color: #2d2d2d;
  font-size: 0.75rem;
  padding-top: 10px;
  margin-bottom: 5px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}
.offerCard {
  background: rgba(255, 255, 255, 0.95);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 10px 0;
  z-index: 3;
}
.offerCard .icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.icon img {
  display: block;
  width: 100%;
}

.hideOfferCard {
  z-index: 30;
}

.offerCardDetails {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  align-items: center;
  justify-content: center;
}

.noExternalId {
  color: #808080;
}
.info {
  font-size: 0.75rem;
  color: #808080;
  padding-left: 34px;
}
.switchContainer {
  border-top: 1px solid #ddd;
  padding-top: 15px;
}
.collectDateLabel {
  top: -15px;
}

.collectDateInput {
  width: 140px;
}
.collectDateText {
  font-size: 0.8rem;
}
.disabledLink {
  pointer-events: none;
}

.smsNotification {
  margin-right: 2px;
  height: 24px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  padding: 5px;
}
.smsNotification img {
  filter: contrast(0%) brightness(200%);
  margin-right: 6px;
}

.productSecondName {
  border: 1px solid #ddd;
  display: inline-flex;
  padding: 2px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.productSecondName img {
  cursor: pointer;
}

.dateImg {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-right: 4px;
}
.dateImg img {
  filter: contrast(0%) brightness(100%);
  width: 17px;
  height: 17px;
}

.orderSignature {
  color: #01c9d5;
}
.orderSignature:hover {
  color: #212529;
  cursor: pointer;
}

.border {
  border-left: 1px solid #777;
  margin-left: 6px;
  margin-right: 5px;
  width: 1px;
  height: 16px;
}

.spinnerContainer {
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formHeader {
  color: #999;
  font-size: 0.81rem;
  margin: 0 0 10px;
  font-weight: 400;
  line-height: 1.2;
}
.offers {
  padding: 15px;
  width: 80%;
  max-height: 70%;
  overflow: auto;
  border-radius: 5px;
}
.label {
  font-size: 0.75rem;
  padding: 0 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 18px;
}
.item {
  padding: 8px 0;
}
.link {
  padding: 1px 5px;
  background: url(~assets/images/121.svg) no-repeat top right;
}
.disabledOffer {
  opacity: 0.9;
}

.priorityLabel {
  font-size: 16px;
}
.prioritySelectedIconBackground {
  background: rgba(1, 1, 1, 0.11);
  border-radius: 15px;
  padding: 5px;
}
.priorityButton {
  border-width: 0;
}
.priorityDropdownList {
  width: 42%;
}
.priorityInputLabel {
  font-size: 12px;
  color: #808080;
  display: inline-flex;
  z-index: 2;
}

.compareListingsButton {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: block;
  margin: 0 auto;
}

.drawer {
  width: 700px !important;
  max-width: 700px !important;
  min-width: 700px !important;
}

.courierLogo {
  max-height: 100%;
  height: 100%;
  min-height: 100%;
  width: 100%;
}

.courierLogoWrapper {
  display: flex;
  align-items: center;
  padding: 6px 0;
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  height: 100%;
}
