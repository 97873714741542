.mainContainer {
  padding: 16px;
}

.content {
  background: var(--neutralWhite100);
  border-radius: 4px;
  box-shadow: 0px 6px 16px -6px #0000001a;
}

.contentHeader {
  padding: 16px 15px;
}

.invoiceDatesContainer {
  display: flex;
  height: 42px;
  gap: 16px;
  margin-bottom: 20px;
  padding: 0px 0px 8px 0px;
}

.recipientDetails {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: right;
}

.actionButtonsContainer {
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0px 0px 8px 0px;
  gap: 10px;
  margin-top: 25px;
}

.currencySelector {
  display: flex;
  gap: 4px;
  height: 32px;
  padding: 8px 0px 4px 0px;
}

.activeTag {
  cursor: pointer !important;
}

.disabledTag {
  cursor: not-allowed !important;
}

.sellerSelectorContainer {
  height: 56px;
  width: 320px;
}

.amountDisplay {
  width: 100%;
  justify-content: end !important;
  text-align: right;
}

.summarySection {
  padding: 16px 0px;
}

.paymentDetailsSection {
  padding: 0px 20px 8px 16px !important;
}

.paymentDetailsSectionContainer {
  width: 140px;
}

.paymentDetailsSectionSummaryAmount {
  width: 120px;
}

.exchangeRateSection {
  padding: 16px 0px;
}

.exchangeRateSectionSummary {
  padding: 8px 20px 8px 0px !important;
}

.exchangeRateSectionSummaryAmount {
  width: 120px;
}
