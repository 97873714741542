.manufacturerSelector {
  max-height: 26px !important;
  display: flex;
  align-items: center;
}

.manufacturerSelectorInput {
  z-index: 1;
}

.addIndexOrPackagesTable {
  min-height: 70vh;
  height: 70vh;
  max-height: 70vh;
}

.stateFilter {
  height: 34px;
}
