.price {
  font-size: 1.1rem;
  position: relative;
}
.price input {
  height: 26px;
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 5px;
  margin: 0 4px;
  cursor: pointer;
  width: 100px !important;
  text-align: left;
  justify-content: flex-end;
  font-size: 1.1rem;
  margin-right: 5px !important;
  padding-right: 8px !important;
  z-index: 1;
  background: transparent;
  position: relative;
}
.price input:hover,
.price input:focus {
  background: #fff;
}
.price input:hover {
  border-color: #ddd;
  outline: 0;
}
.price input:focus {
  border-color: #765ee3;
  outline: 0;
}
.price .unit {
  position: absolute;
  right: 5px;
  z-index: 0;
  line-height: 1.8rem;
  font-size: 0.7rem;
}

.unitAmountPrice input {
  min-width: 150px;
}

.productSecondName {
  border: 1px solid #ddd;
  display: inline-flex;
  padding: 2px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.productSecondName img {
  cursor: pointer;
}

.lastElementBorder {
  box-shadow: 0px 0px 0px 7px white, 0px 0px 0px 8px #755fe2;
  border-radius: 1px;
  margin-top: 5px;
}

.dangerBorder {
  border-radius: 1px;
}
.purpleBorder {
  border: 1px solid #755fe2;
}

.productRow {
  position: relative;
  min-height: 52px;
}

.productRow .editIcon {
  display: inline-flex;
  visibility: hidden;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
}

.product {
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  /* max-width: 225px; */
}

.productName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  overflow: hidden;
}

.versionBox img {
  width: 18px;
  height: 18px;
  background: #eee;
}
.versionBox .label {
  display: inline-flex;
  width: fit-content;
  font-size: 0.75rem;
  height: 18px;
  line-height: 18px;
  padding: 0 3px;
  background: #eee;
  border: 0;
  white-space: nowrap;
}

.noAttributes {
  color: #808080;
  font-size: 0.9rem;
}

.weight {
  font-size: 0.9rem;
  color: #808080;
  font-weight: 500;
}
