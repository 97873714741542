.package {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #ddd dashed;
  margin-top: 10px;
  padding-bottom: 5px;
}
.label {
  display: block;
  font-size: 0.84rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.badge {
  border: 1px solid #ddd;
  font-size: 0.81rem;
  border-radius: 4px;
  line-height: 20px;
  padding: 0 3px;
  height: 24px;
}

.btn {
  max-height: 37px;
}
